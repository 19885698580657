<template>
  <div class="row">
    <div class="col-md-1 voucher-image" style="margin-right: 175px" >
      <div style="width:105px ; height:100%; position: relative; background: #3254CB; margin-left: 13px"
           class="border border-info  d-flex align-items-center justify-content-center">
        <div class="text-center">
          <img src="/media/gift-icon.png">
          <p class="mt-2" style="color: white ; font-size: 13px">Quà tặng</p>
        </div>
      </div>
    </div>
    <div class="col-md-5 voucher-detail text-left">
      <p style="margin-top: 8px ; margin-bottom: 10px">Tên: {{ item.name }}</p>
      <div v-if="item.type === TYPE_GIFT">
        <p v-if="item.value" style="margin-top: 8px ; margin-bottom: 10px;">
          <span class="badge badge-info">Giá trị quy đổi tiền mặt {{ item.value | formatVND }}</span>
        </p>
        <p v-else style="margin-top: 8px ; margin-bottom: 10px;">
          <span class="badge badge-secondary">Không có giá trị quy đổi tiền mặt</span>
        </p>
      </div>
      <span class="discount" v-if="item.unit == 1">
              Giảm ngay:
              {{ item.value | formatVND }}
            </span>
      <span class="discount" v-if="item.unit == 0">
              Giảm ngay:
              {{ item.value }} %
            </span>
      <p class="end_date" v-if="item.end_date" style="margin-bottom: 8px"> Ngày hết hạn: {{
          item.end_date
        }} </p>
      <p class="end_date" v-if="!item.end_date" style="margin-bottom: 8px"> Chưa có thời hạn</p>
      <p @click="showDetailPromotion(item)" style="color:#2045C9; cursor: pointer">Xem chi tiết</p>
    </div>
    <div v-if="!meCheckSelectGift && !meCheckPickVoucherExchange" class="col-md-3 voucher-action text-right ">
      <div>
        <button :disabled="disableAction" class="btn " style="background-color: #472F92; color: white ; width: 130px"
                @click="pickGift">
          Nhận quà
        </button>
        <button v-if="item.value"
                :disabled="disableAction"
                class="btn  mt-2" style="border-color: #472F92; color: #472F92; background-color: white;"
                @click="pickPrice">Quy đổi
          voucher
        </button>
      </div>
    </div>
    <div v-else class="col-md-3 voucher-action text-right ">
      <button class="btn " style="background: #A70000;color: white;width: 130px" @click="cancelGift">
        Hủy áp dụng
      </button>
    </div>
  </div>
</template>

<script>
import {TYPE_GIFT} from "../../../../core/config/promotionOption";

export default {
  name: "GiftDialog",
  props: {
    item: null,
    listPromotions: {
      type: Array,
      default: () => {
        return [];
      }
    },
    giftSelect: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },
  data() {
    return {
      giftDialogVisible: false,
      TYPE_GIFT: TYPE_GIFT
    }
  },
  methods: {
    pickPrice() {
      this.$emit('pickPrice', this.item);
    },
    pickGift() {
      this.$emit('pickGift', this.item);
    },
    showDetailPromotion() {
      this.$emit('showDetailPromotion', this.item);
    },
    cancelGift() {
      this.$emit('cancelGift', this.item);
    }
  },
  computed: {
    disableAction() {
      if (this.giftSelect) {
        return true;
      }
      return false;
    },
    meCheckSelectGift() {
      if (!this.giftSelect) {
        return false;
      }
      if (this.giftSelect.id === this.item.id) {
        return true;
      }
      // Check trong mảng voucher quy đổi
      const foundItem = this.listPromotions.find(item => item.id === this.item.id);
      if (foundItem) {
        return true;
      }
      return false;
    },
    meCheckPickVoucherExchange() {
      const foundItem = this.listPromotions.find(item => item.id === this.item.id);
      if (foundItem) {
        return true;
      }
      return false;
    }
  }
}
</script>

<style scoped>

</style>