<template>
  <div class="card">
    <div class="card-header">
      <div class="card-title">
        <p>Xem phiếu đăng ký học tập</p>
      </div>
    </div>
    <div class="card-body">
      <RegisterStudyFormPartner
          :isShow="true"
          :contract_prop="contract"
          :verify="verify">
      </RegisterStudyFormPartner>
    </div>
  </div>
</template>

<script>

import {CONTRACT_ONLINE_GET_BY_ID} from "@/core/services/store/contract/contractOnline.module";
import RegisterStudyFormPartner from "@/views/pages/customer/components/RegisterStudyFormPartner";

export default {
  name: "ShowContractPartner",
  components: {RegisterStudyFormPartner},
  data() {
    return {
      contract: {
        is_older: true,
        tuition_payment_id: '',
        name: '',
        phone: '',
        phone_other: '',
        email: '',
        birthday: '',
        center_id: '',
        branch_id: '',
        total: '',
        courses: [],
        parent: {
          type: 'bo',
          other_value: '',
          name: '',
          phone: '',
          phone_other: '',
        }
      },
      verify: {
        otp: '',
        loading_send: true,
        loading_verify: true
      },
    }
  },
  mounted() {
    this.getById();
  },
  methods: {
    getById() {
      let id = this.$route.params.contract_id;
      this.$store.dispatch(CONTRACT_ONLINE_GET_BY_ID, id).then((data) => {
        this.contract = data.data;
      })
    }
  }
}
</script>

<style scoped>

</style>